import Head from 'next/head'
import { useContext, useRef } from 'react'
import { ThemeContext } from 'styled-components'

import Hero from 'modules/ABTestVariations/Hero'
import HowItWorks from 'modules/ABTestVariations/HowItWorks'
import Introduction from 'modules/ABTestVariations/Introduction'
import PricingPlan from 'modules/ABTestVariations/PricingPlan'
import Results from 'modules/ABTestVariations/Results'
import SellSmarter from 'modules/ABTestVariations/SellSmarter'
import SignUp from 'modules/ABTestVariations/SignUp'
import Testimonials from 'modules/ABTestVariations/Testimonials'
import FeaturedOnMedia from 'modules/Marketing/FeaturedOnMedia'
import RecentlySoldListings from 'modules/RecentListings/RecentlySoldListings'

const HomePageB = () => {
  const theme = useContext(ThemeContext)

  const ref = useRef<HTMLDivElement>(null)

  return (
    <>
      <Head>
        <title>HomeLister | The better way to sell your home.</title>
        <meta
          name="description"
          content="The better way to sell your home is with HomeLister. Keep your equity. Low flat fees. Exceptional service."
        />
        <meta
          name="keywords"
          content="Sell Your Home, Sell My House, For Sale By Owner Alternative, MLS Listing, HomeLister"
        />
      </Head>
      <Hero destinationRef={ref} />
      <Introduction introductionRef={ref} />
      {/* SALE PRICE SLIDER */}
      <SellSmarter />
      {/* FEATURED ON MEDIA */}
      <FeaturedOnMedia backgroundColor={theme.colors.neutral25} />
      {/* RESULTS */}
      <Results />
      {/* PRICING PLANS */}
      <PricingPlan />
      {/* HOW IT WORKS */}
      <HowItWorks />
      {/* TESTIMONIALS */}
      <Testimonials />
      {/* RECENTLY SOLD HOMES */}
      <RecentlySoldListings />
      {/* SIGN UP */}
      <SignUp />
    </>
  )
}

export default HomePageB
