// import { NextPageContext } from 'next'
// import cookie from 'cookie'
import HomePage from './home'

// import { activateVwoTestServerside } from 'services/vwo'

// const isPrefetchedNextJSPage = (url: string | undefined) => url?.includes('.json')

// const CAMPAIGN_KEY = 'homepage-v2'

const IndexPage = () => HomePage()

export default IndexPage

/**
 * This code powers our VWO a/b testing for the homepage. If there is an ongoing/upcoming test,
 * it should be uncommented and the experimentKey should be replaced with the new VWO campaing key.
 *
 * Additional configuration steps can be found here:
 * https://homelister.atlassian.net/wiki/spaces/HOMELISTER/pages/341639169/A+B+Testing
 *
 * Current Status: DISABLED
 */
// export async function getServerSideProps(ctx: NextPageContext) {
//   const parsedCookies = cookie.parse(ctx.req?.headers.cookie || '')
//   const experimentKey = process.env.IS_PROD === 'true' ? CAMPAIGN_KEY : `${CAMPAIGN_KEY}-dev`
//   if (experimentKey) {
//     const { variations } = await activateVwoTestServerside(experimentKey, parsedCookies, ctx.res)
//     const variation = variations?.[experimentKey]

//     if (variation && variation === 'Variation-1' && !isPrefetchedNextJSPage(ctx.req?.url)) {
//       const [path, queryString] = ctx.req?.url?.split('?') ?? []
//       const destination = `${path}d/home${queryString ? `?${queryString}` : ''}`
//       return {
//         redirect: {
//           permanent: false,
//           destination,
//         },
//       }
//     }
//   }
//   return { props: { } }
// }
